<template>
<div>
  <div class="container-fluid">
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
          <h4 class="text-themecolor">{{pageTitle}}</h4>
      </div>
      <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">{{pageTitle}}</li>
            </ol>
          </div>
      </div>
    </div>
    <div 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{pageTitle}}</h5>

        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form v-on:submit.prevent="handleSubmit(doSubmitAccount)">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Full Name 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="user.fullName"
                        type="text" 
                        class="form-control" 
                        placeholder="Full Name" 
                      /> 
                      <VValidate name="Full Name" v-model="user.fullName" :rules="{required : 1, min : 3, 
                      regex:/^[a-zA-Z]+(?: [a-zA-Z]+)*$/gm }" />
                    </div>
                    <div class="form-group">
                      <label class="control-label">
                        Mobile Number 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="user.phone"
                        type="text" 
                        class="form-control" 
                        placeholder="Mobile Number" 
                      /> 
                      <VValidate name="Mobile Number" v-model="user.phone" :rules="{required:1, regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/ }" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Email Address 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="user.email"
                        type="email" 
                        class="form-control" 
                        placeholder="Email" 
                      /> 
                      <VValidate name="Email Address" v-model="user.email" rules="required|email" />
                    </div>
                    <div class="form-group">
                      <label class="control-label">
                        Username 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="user.username"
                        type="text" 
                        class="form-control" 
                        placeholder="Username" 
                      /> 
                      <VValidate name="Username" v-model="user.username" :rules="{required: 1, regex: /^(?=.{4,20}$)(?:[a-zA-Z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/}" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Password 
                        <span v-if="!user.id" class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="user.password"
                        type="password" 
                        class="form-control" 
                      /> 
                      <VValidate name="Password" v-model="user.password" rules="min: 5"/>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">
                        Confirm Password 
                        <span v-if="!user.id" class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="user.password2"
                        type="password" 
                        class="form-control" 
                      /> 
                      <VValidate name="Confirm Password" v-model="user.password2" :rules="user.password === '' || user.password === undefined ?'confirmed:Password|min: 3' : 'required|confirmed:Password|min: 3'" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">
                    Profile Photo
                  </label>
                  <Uploader v-model="row.bu_avatar" type="user" label="Photo"/>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                  <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>
        
      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import Gen from '@libs/Gen.js'

let $ = global.jQuery
let _ = global._
export default{
  extends:GlobalVue,
  components:{},
  data(){
    return {
      mrLevel:[],
    }
  },
  methods: {
    
      apiGetAccount(){
        this.data.data = false
        Gen.apiRest(
            "/get/"+"Account/"+ this.user.id
        ).
        then(res=>{
            this.loadingOverlay = false
            this.row = res.data.row;
        })
        .catch(()=>{
            this.loadingOverlay = false
        })
      },
      doSubmitAccount(){
         return this.doSubmit(
          "/do/"+"Account", 
          _.assign({type:'updateAccount', id: this.user.id, img : this.row.bu_avatar}, this.user), 
          (type, resp)=>{
            if(type=='success'){
              this.$router.push({name:this.$route.name})
              this.user.avatar = this.row.bu_avatar
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                }else{
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      }
  },
  mounted(){
    this.apiGet()
    this.apiGetAccount()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>